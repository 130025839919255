<template lang="pug">
.container(ref="container")
</template>

<script>
import {
  Scene,
  OrthographicCamera,
  PerspectiveCamera,
  WebGLRenderer,
  SphereGeometry,
  Mesh,
  MeshStandardMaterial,
  HemisphereLight,
  DirectionalLight,
  VideoTexture,
  LinearFilter,
  UnsignedByteType,
  PMREMGenerator,
  TextureLoader,
} from 'three';

import GLTFLoader from 'three-gltf-loader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { AnaglyphEffect } from 'three/examples/jsm/effects/AnaglyphEffect.js';

import { onMounted, ref } from 'vue';

window.__DEBUG__ = false;

function createBubble(options) {
  const {
    size,
    position,
    rotation,
    color,
    map,
    metalnessMap,
    heightMap,
    alphaMap,
    envMap,
  } = options;
  const geometry = new SphereGeometry(size, 64, 64);
  const material = new MeshStandardMaterial({
    color: 0xfffffff,
    map: map,
    envMap: envMap,
    // envMapIntensity: 6.0,
    // metalnessMap: metalnessMap,
    alphaMap: alphaMap,
    transparent: true,
    metalness: 0.7,
    roughness: 0.01,
    // bumpMap: heightMap,
    // bumpScale: 0.5,
    // displacementMap: heightMap,
    // displacementScale: 1.0,
  });

  const sphere = new Mesh(geometry, [material]);

  sphere.position.x = position.x;
  sphere.position.y = position.y;
  sphere.position.z = position.z;

  sphere.rotation.x = rotation.x;
  sphere.rotation.y = rotation.y;
  sphere.rotation.z = rotation.z;

  sphere.castShadow = true;
  sphere.receiveShadow = true;

  return sphere;
}

function loadVideoTexture(src) {
  const video = document.createElement('video');
  return new Promise((resolve, reject) => {
    video.src = src;
    video.load();

    // workaround for wechat
    video.setAttribute('webkit-playsinline', 'webkit-playsinline');
    video.setAttribute('x-webkit-airplay', 'true');
    video.setAttribute('playsinline', 'true');
    video.setAttribute('x5-video-player-type', 'h5');
    video.setAttribute('x5-video-player-fullscreen', 'false');
    video.preload = 'auto';

    video.muted = true;
    video.loop = true;
    const videoLoadedListener = (e) => {
      if (e.type == 'canplay') {
        video.play();
        const videoTexture = new VideoTexture(video);
        videoTexture.minFilter = LinearFilter;
        videoTexture.magFilter = LinearFilter;
        resolve(videoTexture);
      } else {
        reject(e);
      }
      video.removeEventListener('canplay', videoLoadedListener);
      video.removeEventListener('abort', videoLoadedListener);
      video.removeEventListener('error', videoLoadedListener);
    };
    video.addEventListener('canplay', videoLoadedListener);
    video.addEventListener('abort', videoLoadedListener);
    video.addEventListener('error', videoLoadedListener);
  });
}

function loadHdrTexture(url, pmremGenerator) {
  return new Promise((resolve, reject) => {
    new RGBELoader().setDataType(UnsignedByteType).load(
      url,
      (texture) => {
        return resolve(pmremGenerator.fromEquirectangular(texture).texture);
      },
      undefined,
      (err) => {
        return reject(err);
      },
    );
  });
}

function loadGltf(url) {
  const loader = new GLTFLoader();
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      (gltf) => {
        resolve(gltf);
      },
      undefined,
      (err) => {
        reject(err);
      },
    );
  });
}

function convertLightCastingShadow(light) {
  light.castShadow = true;
  light.shadow.radius = 8; // Make the light soft
  light.shadow.mapSize.width = 256;
  light.shadow.mapSize.height = 256;
}

export default {
  name: 'Bubble2',
  setup() {
    const container = ref(null);
    onMounted(async () => {
      const stats = new Stats();
      container.value.appendChild(stats.dom);

      const scene = new Scene();
      const aspect = window.innerWidth / window.innerHeight;
      const camera = new OrthographicCamera(-60 * aspect, 60 * aspect, 60, -60, 0.01, 100);
      // const camera = new PerspectiveCamera(100, window.innerWidth / window.innerHeight, 0.01, 100);

      const renderer = new WebGLRenderer({ antialias: true, powerPreference: 'high-performance' });
      renderer.shadowMap.enabled = true;
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.value.appendChild(renderer.domElement);

      // Camera Initializing
      camera.position.z = 60;
      camera.zoom = aspect > 1.0 ? 2.0 : 0.8;
      camera.updateProjectionMatrix();

      // Lighting
      const hemisphereLight = new HemisphereLight(0xffffff, 0xffffff, 1.0);
      scene.add(hemisphereLight);
      const directionalLight = new DirectionalLight(0xffffff);
      directionalLight.position.set(0, 30, -100);
      scene.add(directionalLight);
      const directionalLight2 = new DirectionalLight(0xffffff);
      directionalLight2.position.set(0, -30, -100);
      scene.add(directionalLight2);
      // const directionalLight2 = new DirectionalLight(0x333333);
      // convertLightCastingShadow(directionalLight2);
      // scene.add(directionalLight2);
      // Load Models
      // const textModel = (await loadGltf(require('@/assets/bubble/models/pickyourcolor.gltf')))
      //   .scene;
      // const textMaterial = new MeshStandardMaterial({
      //   color: 0xffffff,
      //   metalness: 0.0,
      //   roughness: 1.0,
      // });
      // textModel.traverse((o) => {
      //   if (o.isMesh) {
      //     o.material = textMaterial;
      //     o.receiveShadow = true;
      //   }
      // });
      // textModel.scale.set(0.05, 0.05, 0.05);
      // textModel.position.set(5.0, -2.0, -15.0);
      // // textModel.castShadow = true;
      // textModel.receiveShadow = true;
      // scene.add(textModel);

      // Load Textures
      const pmremGenerator = new PMREMGenerator(renderer);
      const bubbleHeightMap = await loadVideoTexture(require('@/assets/bubble/maps/noise.mp4'));
      const envMap = await loadHdrTexture(require('@/assets/bubble/env/env1.hdr'), pmremGenerator);
      // scene.environment = envMap;
      // scene.background = envMap;
      // const envMap = new TextureLoader().load(require('@/assets/bubble/env/test_env.jpg'));
      const alphaMap = new TextureLoader().load(require('@/assets/bubble/maps/alphaMap.png'));
      const metalnessMap = new TextureLoader().load(require('@/assets/bubble/maps/metalness.png'));

      // Create Bubbles
      const bubbles = [
        {
          //1-黄色
          size: 10,
          position: { x: -8, y: 0, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xffffff,
          map: new TextureLoader().load(require('@/assets/bubble/maps/color.png')),
          envMap: envMap,
          metalnessMap: metalnessMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //1-黄色
          size: 10,
          position: { x: 5, y: 13, z: -10 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xffffff,
          map: new TextureLoader().load(require('@/assets/bubble/maps/color2.jpg')),
          envMap: envMap,
          metalnessMap: metalnessMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //1-黄色
          size: 10,
          position: { x: 13, y: -13, z: -10 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xffffff,
          map: new TextureLoader().load(require('@/assets/bubble/maps/color3.jpg')),
          envMap: envMap,
          metalnessMap: metalnessMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
      ].map((options) => createBubble(options));
      bubbles.forEach((bubble) => scene.add(bubble));

      const controls = new OrbitControls(camera, renderer.domElement);
      // controls.autoRotate = true;
      const bubbleSpeeds = Array.apply(null, Array(bubbles.length)).map(
        () => Math.random() * 0.02 - 0.01,
      );
      let angle = 0.0;
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        bubbles.forEach((bubble, i) => {
          bubble.rotation.z += bubbleSpeeds[i];
        });
        angle += 0.01;
        directionalLight.position.x = Math.cos(angle) * 30.0;
        directionalLight.position.y = Math.sin(angle) * 30.0;
        directionalLight2.position.x = -Math.cos(angle) * 30.0;
        directionalLight2.position.y = -Math.sin(angle) * 30.0;
        renderer.render(scene, camera);
        stats.update();
      };

      animate();
    });

    return { container };
  },
};
</script>

<style lang="stylus" scoped>
.container
  position absolute
  margin 0
  top 0
  left 0
</style>
